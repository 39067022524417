import { lazy, useContext } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ContextStore } from '../hooks/ContextStore';
import UiPaths from '../paths/uiPaths';

const DashboardPage = lazy(() => import('../pages/DashBoard'));
const LoginPage = lazy(() => import('../pages/Login'));
const NotFoundPage = lazy(() => import('../pages/NotFoundPage'));

const RouteProvider = () => {
  const { Login, Dashboard } = UiPaths;
  const {contextStore, setContextStore} = useContext(ContextStore)
  let isLoggedIn: boolean = true;
  return (
    <BrowserRouter>
    {contextStore.user._id ?       
    <Routes>
        <Route path={Dashboard} element={<DashboardPage />} />
        <Route path = "/*" element= {<Navigate to={Dashboard} />} />
      </Routes> : 
            <Routes>
          <Route path={Login} element={<LoginPage />} />
    <Route path = "/*" element= {<Navigate to={Login} />} />
          </Routes>}
    </BrowserRouter>
  );
};

export default RouteProvider;
