import axios from "axios"
import { actions } from "./actions"

export const baseUrl = "https://restapi.mybestprod.xyz"
export const dispatch = async (action: string, body?: Object, token?: string, headerParams?: any) => {
    try{
        let response = {} as any
        let axiosOptions = {} as any
        switch(action){
            case actions.getAllStocks:
                axiosOptions = getAxiosOptions("POST", `${baseUrl}/view/stock/all`, body)
                response = await axios(axiosOptions)
                return response.data
            case actions.login:
                axiosOptions = getAxiosOptions("POST", `${baseUrl}/auth/login`, body, token)
                response = await axios(axiosOptions)
                return response.data
            case actions.getUser:
                axiosOptions = getAxiosOptions("GET",  `${baseUrl}/user`, {}, token)
                response = await axios(axiosOptions)
                return response.data
            case actions.xlsxBotSendMail:
                axiosOptions = getAxiosOptions("POST", `${baseUrl}/sendmail/xlsxbot`, body, token)
                response = await axios(axiosOptions)
                return response.data
            case actions.readyToShipBotSendMail:
                axiosOptions = getAxiosOptions("POST", `${baseUrl}/sendmail/readytoshipbot`, body, token)
                response = await axios(axiosOptions)
                return response.data
            case actions.changeStatusReadyToShip:
                axiosOptions = getAxiosOptions("POST", `${baseUrl}/bot/readytoship`, body, token)
                response = await axios(axiosOptions)
                return response.data
        }
    }
    catch(err: any){
        console.log(err)
        return err.response.data
    }
}

const getAxiosOptions = (method: string, url: string, body?: Object, token?: string) => {
    const headers = {
        "x-auth-token": token ? token : "",
        "Content-Type": "application/json",
    };
    switch (method) {
        case "GET":
            return {
                method: "GET",
                url,
                headers,
            };
            break;
        case "POST":
            return {
                method: "POST",
                url,
                headers,
                data: body,
            };
            break;
        case "PUT":
            return {
                method: "PUT",
                url,
                headers,
                data: body,
            };
            break;
        case "DELETE":
            return {
                method: "DELETE",
                url,
                headers,
            };
            break;
    }
};
