import { FC, Fragment, useState, useEffect } from 'react';
import './App.css';
import { actions } from './dispatch/actions';
import { dispatch } from './dispatch/dispatch';
import { ContextStore, IContextStore } from './hooks/ContextStore';
import { IErrorResponse } from './interfaces/formData/ErrorResponse.interface';
import { IUserResponse } from './interfaces/formData/UserResponse.interface';
import { Spinkit } from './modals/spinkit/Spinkit';
import RouteProvider from './routes/RouteProvider';

const App: FC = () => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [contextStore, setContextStore] = useState<IContextStore>({
    setShowSpinner,
    products: [],
    productGroups: [],
    user: {} as IUserResponse
  })
  useEffect(() => {
    (async () => {
      //   contextStore.setShowSpinner(true);
      const vToken = localStorage.getItem('token');
      console.log(vToken)
      if (vToken) {
        contextStore.setShowSpinner(true);
        const userResponse: IUserResponse | IErrorResponse = await dispatch(
          actions.getUser,
          {},
          vToken
        );
        if ('error' in userResponse) {
          contextStore.setShowSpinner(false);
          return console.log(userResponse.error.message);
        }
        let vDate = new Date(Date.now())
        vDate.setMonth(vDate.getMonth() - 2)
        setContextStore((past: any) => {
          return { ...past,  user: userResponse };
        });
        contextStore.setShowSpinner(false);
      }
    })();
  }, []);
  return (
    <ContextStore.Provider value={{contextStore, setContextStore}}>
      <Fragment>
      {showSpinner && <Spinkit/>}
      {/* <AuthContextProvider>
        
      </AuthContextProvider>
      <Toaster position="top-right" /> */}
      <RouteProvider />
    </Fragment>
    </ContextStore.Provider>
  );
};

export default App;