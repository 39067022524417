import { createContext,Dispatch, SetStateAction } from "react";
import { IProductTableData } from "../interfaces/formData/ProductTableData.interface";
import { ISkuTableData } from "../interfaces/formData/SkuTableData.interface";
import { IUserResponse } from "../interfaces/formData/UserResponse.interface";

export interface IContextStore {
    setShowSpinner: Dispatch<SetStateAction<boolean>>
    products: Array<ISkuTableData>
    productGroups: Array<IProductTableData>
    user: IUserResponse
}
export const ContextStore = createContext<{contextStore: IContextStore, setContextStore: Dispatch<SetStateAction<IContextStore>>}>({
    contextStore: {} as IContextStore, 
    setContextStore: () => {}
})